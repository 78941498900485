<template>
  <div class="dynamic-code-component">
    <div
      class="background-div"
      :class="[selectedAccount.username ? 'selected-account' : '']"
    >
      <h4 v-if="selectedAccount.username" class="user-name">
        {{ selectedAccount.username }}
      </h4>
      <div class="code-div">
        <h3>Dynamic code</h3>
        <div class="spinner">
          <Countdown
            :dynamicCode="accountInfo.access_token"
            :remainingTime="remainingTime"
            @animationend="handleAnimationEnd"
          />
        </div>
      </div>
    </div>
    <div class="logo-container">
      <img src="../../public/GLT-LOGISTICS.svg" alt="GLT-LOGO" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Countdown from "@/components/Countdown.vue";

export default {
  name: "DynamicCodeComponent",
  components: {
    Countdown,
  },
  data() {
    return {
      accountInfo: {
        access_token: null,
      },
      remainingTime: 0,
    };
  },
  computed: {
    ...mapGetters({
      selectedAccount: "account/getActiveAccount",
    }),
  },
  watch: {
    async selectedAccount() {
      this.getToken();
    },
  },
  methods: {
    handleAnimationEnd() {
      this.getToken();
    },
    async getToken() {
      this.accountInfo = await this.$store.dispatch(
        "account/getAccountToken",
        this.selectedAccount.username,
      );
      const generatedAt = new Date(this.accountInfo.generate_at).getSeconds();
      let remainingTime = generatedAt;
      if (remainingTime <= 30) {
        remainingTime = 30 - remainingTime;
      } else {
        remainingTime = 60 - remainingTime;
      }
      this.remainingTime = remainingTime;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.dynamic-code-component {
  width: 100%;
  max-width: 400px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 30px;
}

.logo-container {
  width: 60%;
  margin-top: 85px;
}

.background-div {
  background-color: #fcfcfc;
  border-radius: 5%;
  height: 56%;
  width: 100%;
  max-height: 350px;
  padding: 60px 20px 30px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.selected-account {
  border: solid 1px #00ff00;
}

h4.user-name {
  margin-bottom: 10px;
}

.spinner {
  width: 150px;
  height: 150px;
}

.code-div {
  background: none;
  border-radius: 5%;
  padding: 2rem 2rem;
  box-shadow: 0px 0px 4px #272d3b33;
  display: flex;
  flex-direction: column;
  align-items: center;
}

h4,
h3 {
  color: #043663;
  font-weight: 900;
}

@media (max-width: 700px) {
  .background-div {
    height: 100%;
    padding: 0;
    justify-content: flex-start;
    overflow: auto;
    box-shadow: inset 0px 0px 4px #00000029;
    border-radius: 15px;
    opacity: 1;
  }
  .logo-container {
    display: none;
  }
  h4.user-name {
    margin-top: 10px !important;
  }
  .dynamic-code-component {
    height: 100%;
    width: 100%;
    max-width: unset;
    margin: 0px;
  }
  .code-div {
    border-radius: unset;
    box-shadow: unset;
    padding-top: 20px;
    flex-direction: row;
    justify-content: space-evenly;
  }
  .user-name {
    margin: 0 !important;
  }
  .code-div {
    display: flex;
    align-items: center;
    padding-bottom: 20px;
  }
}
</style>
