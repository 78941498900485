<template>
  <div class="home">
    <div class="main-container">
      <div class="list-container">
        <accounts-list style="height: 100%; width: 100%"></accounts-list>
      </div>
      <div class="dynamic-code-container">
        <DynamicCodeComponent/>
      </div>
    </div>
  </div>
</template>

<script>
import AccountsList from "../components/AccountsList.vue";
import DynamicCodeComponent from "../components/DynamicCode.vue";

export default {
  name: "Home",
  components: {
    AccountsList,
    DynamicCodeComponent,
  },
};
</script>
<style scoped>
.home {
  width: 100%;
  height: 95vh;
  overflow: hidden;
}

.main-container {
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.list-container {
  height: 100%;
  width: 40%;
  margin-right: 20px;
  min-width: 300px;
}

.dynamic-code-container {
  height: 100%;
  width: 20%;
  min-width: 250px;
}

@media (max-width: 700px) {
  .main-container {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-start;
  }
  .dynamic-code-container {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 35%;
    margin-bottom: 20px;
  }
  .list-container {
    width: 99%;
    height: 65%;
    margin-right: unset;
  }
}

</style>
