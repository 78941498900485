<template>
  <div>
    <div class="card-container box-card">
      <div class="card-header">
        <el-input
          class="card-header_search-input"
          placeholder="Search"
          prefix-icon="el-icon-search"
          v-model="search"
          @change="searchAccount()">
        </el-input>
      </div>
      <div class="card-content">
        <ul class="card-content_list">
          <li v-for="account in accounts" :key="account.username">
            <el-button
              :key="btnKey"
              plain
              class="list-btn"
              :class="[account.username == activeAccount.username ? 'active-btn' : '']"
              v-on:click="getToken(account)">
              {{account.username}}
            </el-button>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'AccountsList',
  data() {
    return {
      accounts: [],
      activeAccount: { username: null },
      search: "",
      btnKey: 0,
    };
  },
  mounted() {
    this.searchAccount();
  },
  methods: {
    getToken(activeAccount) {
      if (activeAccount === this.activeAccount) {
        this.activeAccount = { username: null };
        this.$store.commit('account/setActiveAccount', { username: null });
        this.btnKey += 1;
      } else {
        this.activeAccount = activeAccount;
        this.$store.commit('account/setActiveAccount', activeAccount);
      }
    },
    async searchAccount() {
      this.accounts = await this.$store.dispatch('account/getAccountsList', { search: this.search });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

.card-container{
  height: 100%;
  width: 100%;
  box-shadow: inset 0px 0px 4px #00000029;
  border-radius: 15px;
  opacity: 1;
  max-width: 700px;
}

.card-header{
  width: 100%;
  height: 5%;
  margin-bottom: 10px;
  margin-bottom: 0;
  padding-top: 20px;
  &_search-input{
    width: 95%;
  }
}

.card-content{
  width: 100%;
  height: 90%;
  &_list{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(auto-fill, 75px);
    height: 95%;
    list-style-type: none;
    padding: 0;
    overflow-y: auto;
    &::-webkit-scrollbar-track {
      background-color: #EBEBEB;
    }

    &::-webkit-scrollbar {
      width: 5px;
      background-color: #dee2e6;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 20px;
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
      background-color: rgba(0, 0, 0, 0.1);
    }
    & li{
      padding: 10px;
    }
  }
}

.list-btn{
  font-weight: 300;
  color: #043663;
  width: 100%;
  height: auto;
  min-height: 50px;
  overflow-wrap: break-word;
  white-space: normal;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 5px;
  opacity: 1;
}

.active-btn{
  border: 1px solid #00FF00 !important;
  color: #043663 !important;
}

.list-btn:hover, .list-btn:focus{
  border: 1px solid #00FF00;
  color: #043663;
}

::v-deep .el-input__inner::placeholder{
  font-weight: 500 !important;
  color: #ACAFB5;
  opacity: 100%;
}

::v-deep .el-input__icon{
  color: black;
  font-weight: 900;
}

::v-deep .el-input--prefix .el-input__inner{
  border: none;
  background-color: #FCFCFC;
}

::v-deep .el-card__body{
  width: 100%;
  height: 100%;
}

@media (max-width: 1130px) {
  .card-content{
    &_list{
      display: flex;
      flex-direction: column;
    }
  }
}

@media (max-width: 700px) {
  .card-container{
    border: solid 1px #043663;
    width: 99%;
    height: 99%;
  }
}

</style>
