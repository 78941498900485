<template>
  <div class="countdown">
    <div
      v-show="dynamicCode"
      class="circle"
      id="circle-countdown"
      ref="circlectdw"
      :key="reloadCircle"
    ></div>
    <div v-show="!dynamicCode" class="circle-static"></div>
    <div class="code">{{ dynamicCode }}</div>
  </div>
</template>

<script>
export default {
  name: "CountdownCircle",
  props: {
    dynamicCode: {
      type: String,
      required: false,
    },
    remainingTime: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      reloadCircle: 0,
    };
  },
  watch: {
    dynamicCode() {
      this.reloadCircle += 1;
      setTimeout(() => {
        this.setCircleTime();
      }, 0);
    },
  },
  methods: {
    setCircleTime() {
      const circleElement = this.$refs.circlectdw;
      this.circleEventHandlers(circleElement);
      const remainingTime = 30 - this.remainingTime;
      circleElement.style.animationDelay = `-${remainingTime.toString()}s`;
    },
    circleEventHandlers(circleElement) {
      circleElement.removeEventListener(
        "animationiteration",
        this.animationIterationHandler,
      );
      circleElement.addEventListener(
        "animationiteration",
        this.animationIterationHandler,
      );
    },
    animationIterationHandler() {
      this.$emit("animationend");
    },
  },
};
</script>
<style scoped lang="scss">
.countdown {
  display: inline-flex;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #FAFAFA;
  border-radius: 50%
}

.circle {
  @include circle-countdown;
  transform: rotateY(180deg);
  animation-name: clipCircle;
  animation-duration: 30s;
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(0, 0, 1, 1);
}

.circle-static {
  @include circle-countdown;
}

@keyframes clipCircle {
  0% {
    clip-path: polygon(
      50% 50%,
      50% 00%,
      50% 00%,
      50% 00%,
      50% 00%,
      50% 00%,
      50% 00%,
      50% 00%,
      50% 00%,
      50% 00%
    );
  }
  12.5% {
    clip-path: polygon(
      50% 50%,
      50% 00%,
      00% 00%,
      00% 00%,
      00% 00%,
      00% 00%,
      00% 00%,
      00% 00%,
      00% 00%,
      00% 00%
    );
  }
  25% {
    clip-path: polygon(
      50% 50%,
      50% 00%,
      00% 00%,
      00% 50%,
      00% 50%,
      00% 50%,
      00% 50%,
      00% 50%,
      00% 50%,
      00% 50%
    );
  }
  37.5% {
    clip-path: polygon(
      50% 50%,
      50% 00%,
      00% 00%,
      00% 50%,
      00% 100%,
      00% 100%,
      00% 100%,
      00% 100%,
      00% 100%,
      00% 100%
    );
  }
  50% {
    clip-path: polygon(
      50% 50%,
      50% 00%,
      00% 00%,
      00% 50%,
      00% 100%,
      50% 100%,
      50% 100%,
      50% 100%,
      50% 100%,
      50% 100%
    );
  }
  62.5% {
    clip-path: polygon(
      50% 50%,
      50% 00%,
      00% 00%,
      00% 50%,
      00% 100%,
      50% 100%,
      100% 100%,
      100% 100%,
      100% 100%,
      100% 100%
    );
  }
  75% {
    clip-path: polygon(
      50% 50%,
      50% 00%,
      00% 00%,
      00% 50%,
      00% 100%,
      50% 100%,
      100% 100%,
      100% 50%,
      100% 50%,
      100% 50%
    );
  }
  87.5% {
    clip-path: polygon(
      50% 50%,
      50% 00%,
      00% 00%,
      00% 50%,
      00% 100%,
      50% 100%,
      100% 100%,
      100% 50%,
      100% 00%,
      100% 00%
    );
  }
  100% {
    clip-path: polygon(
      50% 50%,
      50% 00%,
      00% 00%,
      00% 50%,
      00% 100%,
      50% 100%,
      100% 100%,
      100% 50%,
      100% 00%,
      50% 00%
    );
  }
}

.code {
  color: #043663;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-weight: 900;
  z-index: 2;
  font-size: 23px;
}
</style>
